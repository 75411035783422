window.rwd || (window.rwd = {});

$.fn.expandElementSupport = function($expandedWrapper, callback_reset, callback_sync){

    var $expandElement = $expandedWrapper.find(".expand_element");

    if ($expandedWrapper.exists()) {
        /** Domyślnie zwijamy wszystkie elementy ".expand_element" */
        $expandElement.find(".body").slideUp();

        $expandElement.find(".head").onPage("click", function () {
            var el = $(this);
            /**
             * Wywołujemy funkcję callback_reset w której możemy zresetować  (ustawioną wcześniej przez inne funkcje)
             * synchronizację wysokości dla tego elementu
             */
            if(!el.closest($expandElement).hasClass("active")){
                if (callback_reset && typeof(callback_reset) == "function"){
                    callback_reset(el);
                }
                /**Ukrywamy pozostałe elementy z klasą ".expand_element" */
                el.closest(".component_wrapper").find(".expand_element").removeClass("active").find(".body").slideUp();

                el.closest($expandElement).addClass("active").find(".body").slideDown().css("display","block");
            }else{
                /**Ukrywamy już kliknięty i widoczny elementy z klasą ".expand_element" */
                el.closest($expandElement).removeClass("active").find(".body").slideUp(function(){
                    if (callback_sync && typeof(callback_sync) == "function"){
                        callback_sync(el);
                    }
                });
            }
        });
    }
};

;(function($package, ScreenSize, UIComponent) {

    function getExpandedDimension($parent, options) {
        return $parent.outerHeight(true, true) + options.moreSelectorHeight;
    }

    function getCollapsedDimension($items, lastIndex, options) {
        var height = 0;
        $items.each(function(index) {
            if (index == lastIndex) {
                return false;
            }
            height += $(this).outerHeight(true, true);
        });
        return height + options.moreSelectorHeight;
    }

    $package.Expandable = Expandable;
    extendz(Expandable, UIComponent)
    function Expandable(node, options) {
        UIComponent.call(this, node);
        this.options = $.extend({}, Expandable.defaults, options);
        var $node = $(this.node);
        this.$ui = {
            node: $node,
            listWrapper: $node.find(this.options.listWrapperSelector),
            list: $node.find(this.options.listSelector),
            items: $node.find(this.options.itemsSelector),
            more: $node.find(this.options.moreSelector)
        }
        this.init();
        if (this.options.onCondition()) {
            this.on();
        }
        Expandable.all.push(this);
    }
    Expandable.all = [];
    Expandable.defaults = {
        onCondition: function() { return true; },
        index_of_last_visible_block: -1,
        listWrapperSelector: "> .list",
        listSelector: "> .list > UL",
        itemsSelector: "> .list > UL > *",
        moreSelector: "> .more",
        moreSelectorHeight: 0,
        invisibleClass: 'isInvisible',
        opacityClass: 'isOpacity'
    };
    Expandable.prototype.init = function() {
        var that = this;
        this.$ui.node.onPage("click", that.options.moreSelector, function() {
            that.uiToggle();
        });
    }
    Expandable.prototype.isOn = function() {
        return $(this.node).hasClass("expandable_on");
    }
    Expandable.prototype.turningOnTimeout = -1;
    Expandable.prototype.on = function() {
        var that = this;
        clearTimeout(this.turningOnTimeout);
        if ($(this.node).hasClass("carousel_on")) {
            that.turningOnTimeout = setTimeout(function() {
                that.on();
            }, 100);
            return;
        }
        this.on0();
    }
    Expandable.prototype.on0 = function() {
        if (!this.$ui.more[0]) {
            return;
        }
        this.$ui.more.show();
        this.$ui.node.addClass("expandable expandable_on expandable_is_collapsed");
        var slice = this.$ui.items.slice(this.options.index_of_last_visible_block-1);
        slice.first().addClass("expandable_last_visible");
        slice.slice(1).hide().addClass(this.options.invisibleClass);
    }
    Expandable.prototype.off = function() {
        this.$ui.node.removeClass("expandable expandable_on expandable_is_collapsed")
        this.$ui.listWrapper.height("");
        this.$ui.items.show();

        this.$ui.more.hide();
        this.$ui.more.find(".expand").show();
        this.$ui.more.find(".collapse").hide();
    }
    Expandable.prototype.toggle = function() {
        if (this.options.onCondition()) {
            if (this.isOn()) {
                return;
            }
            this.on();
        } else {
            this.off();
        }
    }
    Expandable.prototype.collapse = function() {
        var that = this;
        var $ui = this.$ui;
        var slice = $ui.items.slice(that.options.index_of_last_visible_block-1);
        $ui.node.addClass("expandable_is_collapsed");
        $ui.more.find(".expand").show();
        $ui.more.find(".collapse").hide();
        slice.slice(1).removeClass(that.options.opacityClass)
        $ui.listWrapper
            .css("overflow", "hidden")
            .animate(
                {height:
                    getCollapsedDimension($ui.items, this.options.index_of_last_visible_block, that.options)},
                after);

        function after() {
            slice.first().addClass("expandable_last_visible");
            slice.slice(1).hide();
            $ui.listWrapper.height("").css("overflow", "");
        }

    }
    Expandable.prototype.expand = function() {
        var that = this;
        var $ui = that.$ui;
        $ui.items.removeClass("expandable_last_visible");
        $ui.listWrapper.css("overflow", "hidden").height(
                getCollapsedDimension($ui.items, this.options.index_of_last_visible_block, that.options));
        $ui.items.show();
        $ui.node.removeClass("expandable_is_collapsed");
        $ui.more.find(".collapse").show();
        $ui.more.find(".expand").hide();
        $ui.listWrapper
            .animate({height: getExpandedDimension($ui.list, that.options, that.options)}, after);

        function after() {
            $ui.listWrapper.height("").css("overflow", "");
            $ui.items.addClass(that.options.opacityClass);
        }

    }
    Expandable.prototype.uiToggle = function() {
        var $node = $(this.node);
        if ($node.hasClass("expandable_is_collapsed")) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    Expandable.toggle = function() {
        $.each(this.all, function(index, item) {
            item.toggle();
        });
    }

    $(document).onPage(ScreenSize.SIZE_CHANGE_EVENT_NAME, function(e, data) {
        Expandable.toggle();
    });
})(rwd, rwd.ScreenSize, rwd.UIComponent);
